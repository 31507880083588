import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import {
  fetchSimInventoryExtendedFiltersPagination,
  MaxSimProfilesCountError,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import { AuthError } from 'auth/utils';
import { AutoHideSnackbar } from 'common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from 'common/Snackbar/AlertTitle';
import { useQuery } from 'react-query';
import {
  checkIsLargeReport,
  downloadSimProfilesReportWithBackgroundSupport,
} from './api/simProfileReportsApi';
import { useSorting } from '../../common/hooks/useSorting';
import { LargeExportPrompt } from './LargeExportPrompt';
import { useFilters } from 'simInventory/Components/Filters/hooks/useFilters';
import { useDebounce } from 'usehooks-ts';

type ExportError = {
  title: string;
  description: string;
};

export const SimProfileDownloadReportsExtendedFiltersButtonLargeExport = () => {
  const { sorting } = useSorting('sim-order');

  const { idType, ids, filters } = useFilters();

  const filtersParams = { searchText: ids, filters, chosenIdType: idType };

  const [confirmationRequire, setConfirmitaionRequire] = useState(false);
  const [largeExport, setLargeExport] = useState(false);

  const debouncedSearchText = useDebounce(ids.join() || '', 500);
  const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;
  const { tags, ...otherFilters } = filters;
  const query = useQuery(
    [
      'SimInventoryConfigurable',
      minLengthText,
      idType,
      sorting,
      ...Object.values(otherFilters),
      tags,
    ],
    async ({ signal }) => {
      const data = await fetchSimInventoryExtendedFiltersPagination(0, 100, filtersParams, signal);
      return data.totalNumberOfItems || 0;
    },
    {
      suspense: false,
    },
  );

  const { refetch, isFetching } = useQuery(
    ['sim-inventory', 'reports', 'download', filtersParams, sorting],
    async () => {
      await downloadSimProfilesReportWithBackgroundSupport(filtersParams, sorting);
    },
    {
      retry: 0,
      enabled: false,
      suspense: false,
      onError: (error) => {
        if (error instanceof AuthError) {
          throw error;
        }

        if (error instanceof MaxSimProfilesCountError) {
          setExportError({
            title: t('common.exportFailed'),
            description: t('simInventory.maxSimProfilesCountError', {
              maxSimProfiles: error.maxSimProfilesCount,
            }),
          });
        } else {
          setExportError({
            title: t('common.requestFailed'),
            description: t('common.somethingWrong'),
          });
        }
      },
    },
  );

  const exportReport = async () => {
    const largeExport = await checkIsLargeReport(filtersParams);

    setLargeExport(largeExport);
    setConfirmitaionRequire(largeExport);
    if (!largeExport) {
      refetch();
    }
  };

  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);
  useEffect(() => {
    if (isFetching) {
      setDownloadingSnackbarOpened(true);
    }
  }, [isFetching]);
  const { t } = useTranslation();

  const [exportError, setExportError] = useState<ExportError | null>(null);

  return (
    <>
      <Stack>
        <Button
          startIcon={<FileDownloadIcon />}
          disabled={isFetching}
          color="secondary"
          onClick={exportReport}
        >
          {t('common.export')}
        </Button>
        <LargeExportPrompt
          open={confirmationRequire}
          numberOfCards={query.data || 0}
          onConfirm={() => {
            setConfirmitaionRequire(false);
            refetch();
          }}
          onClose={() => {
            setConfirmitaionRequire(false);
            setLargeExport(false);
          }}
        />
        <AutoHideSnackbar
          severity="success"
          open={downloadingSnackbarOpened && !largeExport}
          onClose={() => {
            setDownloadingSnackbarOpened(false);
          }}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle> {t('common.downloading')}!</AlertTitle>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          severity="success"
          open={downloadingSnackbarOpened && largeExport}
          onClose={() => {
            setDownloadingSnackbarOpened(false);
            setLargeExport(false);
          }}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{`Exporting ${query.data} SIMs`}</AlertTitle>
          <Typography variant="text1White">
            You'll receive an email with a download link when it's ready
          </Typography>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          open={!!exportError}
          severity="error"
          onClose={() => setExportError(null)}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{exportError?.title || ''}!</AlertTitle>
          <Typography variant="text1White">{exportError?.description}</Typography>
        </AutoHideSnackbar>
      </Stack>
    </>
  );
};
