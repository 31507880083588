import React from 'react';
import { useSimInventory } from './useSimInventory';
import { usePrevSearchValue } from 'common/usePrevSearchValue';
import { NoDataMessage } from 'common/components/Table/NoDataMessage';

import { ConfigurableTable } from './ConfigurableTable/TableBase';
import { useSorting } from '../../common/hooks/useSorting';
import { TableLayoutWithPaginationWithoutScrollArea } from 'common/components/Table/TableLayoutWithPaginationWithoutScrollArea';
import { useFilters } from './Filters/hooks/useFilters';

export const SimInventoryTable = React.memo(() => {
  const { filters, ids: searchText, idType: chosenIdType } = useFilters();

  const { sorting, setSortParams } = useSorting('sim-order');

  const {
    simInventory,
    isRefetching,
    pagination: { page, setPage, rowsPerPage, setRowsPerPage, totalNumberOfItems },
    updateTagsForSim,
  } = useSimInventory(searchText, chosenIdType, filters, sorting);

  const prevSearchText = usePrevSearchValue(searchText, isRefetching);

  return (
    <TableLayoutWithPaginationWithoutScrollArea
      noItemsMessage={
        <NoDataMessage searchText={searchText[0]} prevSearchText={prevSearchText?.[0]} />
      }
      fetching={isRefetching}
      page={page}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={(page) => setRowsPerPage(page)}
      totalNumberOfItems={totalNumberOfItems}
      table={
        <ConfigurableTable
          items={simInventory?.items || []}
          onTagsUpdate={updateTagsForSim}
          sort={sorting}
          onSortChange={(columnName, sort) => {
            if (columnName && sort) {
              localStorage.setItem('sim-order', `${columnName}:${sort}`);
              setSortParams(`${columnName}:${sort}`);
            } else {
              localStorage.removeItem('sim-order');
            }
          }}
        />
      }
    />
  );
});
