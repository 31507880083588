import React, { FC } from 'react';

import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';

import { FiltersModalProps } from './ProvisioningFiltersModal.interface';
import { useTranslation } from 'react-i18next';
import { Select } from 'common/components/Inputs/Select/Select';
import { SearchTextField } from 'common/components/Inputs/SearchTextField/SearchTextField';
import { FilterSection } from 'common/components/Filters/FilterSection';
import { FiltersDialog } from 'common/components/Filters/FiltersDialog';
import { FiltersButton } from 'common/components/Filters/FiltersButton';

import { FilterListItem } from 'common/components/Filters/FilterListItem';
import { useModalFilters } from 'common/components/Filters/useModalFilters';
import {
  ProvisioningFilters,
  provisioningSourceFilter,
  provisioningStatusFilter,
} from 'bulkProvisioning/BulkProvisioning.interface';
import { ToggleGroup } from 'common/components/Inputs/ToggleGroup/ToggleGroup';
import { ToggleButton } from 'common/components/Inputs/ToggleGroup/ToggleButton';
import Box from '@mui/material/Box';
import {
  PROVISIONING_SOURCE,
  PROVISIONING_STATUS,
  PROVISIONING_STATUS_LABEL,
} from 'bulkProvisioning/BulkProvisioning.constants';
import { bulkProvisioningFiltersSelectStatus } from 'bulkProvisioning/BulkProvisioningDataTestIds';
import { DateRangePickerMui } from '../../common/components/Inputs/DateRangePickerMui/DateRangePickerMui';
import { isArray } from 'lodash';
import { basicErrorHandling } from '../../common/components/Inputs/DateRangePickerMui/DateRangePickerMui.util';

export const initialFilters: ProvisioningFilters = {
  status: '',
  orderNumber: '',
  source: '',
  uploadDateFrom: null,
  uploadDateTo: null,
};

export const FiltersModal: FC<FiltersModalProps> = ({ filters, onFiltersChange }) => {
  const {
    filtersActive,
    open,
    openModal,
    setOpen,
    apply,
    cancel,
    clear,
    modalFilters,
    setModalFilters,
  } = useModalFilters(initialFilters, filters, onFiltersChange);
  const { t } = useTranslation();

  return (
    <>
      <FiltersButton onClick={openModal} active={filtersActive} />
      <FiltersDialog
        open={open}
        onApply={apply}
        onCancel={cancel}
        onClear={clear}
        onClose={() => {
          cancel();
          setOpen(false);
        }}
      >
        <FilterSection>
          <List>
            <FilterListItem label={t('common.status')} htmlFor="select status">
              <Select
                data-testid={bulkProvisioningFiltersSelectStatus}
                label={t('common.status')}
                name={t('common.status')}
                value={modalFilters.status.toString()}
                onChange={(event) => {
                  const status = provisioningStatusFilter.parse(event.target.value);
                  setModalFilters((prevFilters) => ({
                    ...prevFilters,
                    status: status,
                  }));
                }}
              >
                <MenuItem value="">{t('common.none')}</MenuItem>
                <MenuItem value={PROVISIONING_STATUS.PROCESSING_IN_PROGRESS}>
                  {PROVISIONING_STATUS_LABEL.PROCESSING_IN_PROGRESS}
                </MenuItem>
                <MenuItem value={PROVISIONING_STATUS.PROCESSING_SUCCEED}>
                  {PROVISIONING_STATUS_LABEL.PROCESSING_SUCCEED}
                </MenuItem>
                <MenuItem value={PROVISIONING_STATUS.PROCESSING_FAILED}>
                  {PROVISIONING_STATUS_LABEL.PROCESSING_FAILED}
                </MenuItem>
                <MenuItem value={PROVISIONING_STATUS.DATA_EXTRACTION_PENDING}>
                  {PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_PENDING}
                </MenuItem>
                <MenuItem value={PROVISIONING_STATUS.DATA_EXTRACTION_IN_PROGRESS}>
                  {PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_IN_PROGRESS}
                </MenuItem>
                <MenuItem value={PROVISIONING_STATUS.DATA_EXTRACTION_FAILED}>
                  {PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_FAILED}
                </MenuItem>
              </Select>
            </FilterListItem>
            <FilterListItem label={t('common.orderNumber')} htmlFor="orderNumber">
              <SearchTextField
                data-testid="Order number text field"
                name="orderNumber"
                id="orderNumber"
                placeholder="Type Order Number"
                value={modalFilters.orderNumber}
                onChange={({ target }) => {
                  setModalFilters((prevState) => ({
                    ...prevState,
                    orderNumber: target.value,
                  }));
                }}
              />
            </FilterListItem>
          </List>
        </FilterSection>
        <FilterSection>
          <List>
            <FilterListItem label={t('common.uploadDate')} htmlFor="source">
              <DateRangePickerMui
                disableFuture={true}
                onChange={(value) => {
                  setModalFilters((prevFilters) => ({
                    ...prevFilters,
                    uploadDateFrom: value && isArray(value) && value[0] ? value[0] : null,
                    uploadDateTo: value && isArray(value) && value[1] ? value[1] : null,
                  }));
                }}
                value={[modalFilters.uploadDateFrom, modalFilters.uploadDateTo]}
                allowClearing={true}
                size={'large'}
                onError={(err) => {
                  setModalFilters((prevFilters) => {
                    const validRange = basicErrorHandling(
                      [prevFilters.uploadDateFrom, prevFilters.uploadDateTo],
                      err,
                    );
                    return {
                      ...prevFilters,
                      uploadDateFrom: validRange[0],
                      uploadDateTo: validRange[1],
                    };
                  });
                }}
              />
            </FilterListItem>
            <FilterListItem label={t('common.source')} htmlFor="source">
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <ToggleGroup
                  sx={{
                    width: '100%',
                    justifyContent: 'stretch',
                  }}
                  value={modalFilters.source}
                  onChange={(_event, value) => {
                    if (value === null) {
                      return;
                    }
                    const source = provisioningSourceFilter.parse(value);
                    setModalFilters((prevFilters) => ({
                      ...prevFilters,
                      source,
                    }));
                  }}
                >
                  <ToggleButton sx={{ flex: 1 }} value={PROVISIONING_SOURCE.MANUAL}>
                    Manual
                  </ToggleButton>
                  <ToggleButton sx={{ flex: 1 }} value={''}>
                    Any
                  </ToggleButton>
                  <ToggleButton sx={{ flex: 1 }} value={PROVISIONING_SOURCE.AUTOMATIC}>
                    Automatic
                  </ToggleButton>
                </ToggleGroup>
              </Box>
            </FilterListItem>
          </List>
        </FilterSection>
      </FiltersDialog>
    </>
  );
};
